import { createStore } from 'vuex'

// Modules
import AuthModule from './modules/AuthModule'
import ChatModule from './modules/ChatModule'

export default createStore({
  modules: {
      AuthModule,
      ChatModule
  }
})
