<template>
  <router-view/>
</template>

<script>
import {GET_USER_INFO} from '@/store/modules/AuthModule';
export default {
  methods:{
    async getUserInfo(){
      await this.$store.dispatch(GET_USER_INFO);
    }
  },
  created() {
    if(this.currentCredentials.token){
      this.getUserInfo()
    }
  },
  computed: {
    currentCredentials() {
      return this.$store.getters.get_credentials;
    }
  }
}
</script>