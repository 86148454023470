import {ChatAPIInstance} from "@/plugins/axios";
export const SET_QUESTION = "SET_QUESTION";
export const GET_SHARE_SESSION_ID = "GET_SHARE_SESSION_ID";
export const READ_SHARED_SESSION_ID = "READ_SHARED_SESSION_ID";

const state = {}

const actions = {
    [SET_QUESTION]:async (store, form)=>{
        const { data }  = await ChatAPIInstance({url:'/ai',method:'POST', data:form});
        return data;
    },
    [GET_SHARE_SESSION_ID]:async (store, session_id)=>{
        const { data }  = await ChatAPIInstance({url:'/share/'+session_id,method:'GET'});
        return data;
    },
    [READ_SHARED_SESSION_ID]:async (store, shared_session_id)=>{
        const { data }  = await ChatAPIInstance({url:'/read/'+shared_session_id,method:'GET'});
        return data;
    },
}

const mutations = {}

const getters = {}
export default {
    state,
    actions,
    mutations,
    getters
}
