import { createRouter, createWebHistory } from 'vue-router'

// eslint-disable-next-line no-prototype-builtins
// const isAuthorized = localStorage.hasOwnProperty('token');
// const authGuard = function (to, from, next){
//   if(!isAuthorized) next({ name: 'Login' });
//   else next()
// }

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/default-pages/Login.vue')
  },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: () => import('../views/default-pages/Register.vue')
  // },
  // {
  //   path: '/forgot-password',
  //   name: 'Forgot',
  //   component: () => import('../views/default-pages/ForgotPasswordView.vue')
  // },
  {
    path: '/',
    name: 'HomePage',
    component: () => import('../views/default-pages/ChatPage')
  },
  {
    path: '/chat',
    name: 'ChatPage',
    component: () => import('../views/default-pages/ChatPage')
  },
  {path: "/:pathMatch(.*)*", component: () => import('../views/default-pages/NotFoundPage.vue')}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  document.title = to.meta?.title ?? 'Aggregain';
  let link = document.createElement('link');
  link.setAttribute('rel', 'canonical');
  link.setAttribute('href', location.protocol + '//' + location.host + to.fullPath);
  document.head.appendChild(link);
})

export default router
